/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure - hero component.
 * Use of this component within markdown is possible.
 * Use the tag <hero>hero text</hero> but ensure it is closed.
 */

// Core dependencies
import React from "react";

// Styles
import { hero } from "./hero.module.css";

class Hero extends React.Component {
  render() {
    const { children } = this.props;
    return <div className={hero}>{children}</div>;
  }
}

export default Hero;
