/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure - markdown component.
 */

// Core dependencies
import React from "react";
import rehypeReact from "rehype-react";

// App dependencies
import Hero from "../hero/hero";

// Styles
import { content } from "./markdown.module.css";

let classNames = require("classnames");

class Markdown extends React.Component {
  render() {
    const { children, className } = this.props;
    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: { hero: Hero },
    }).Compiler;
    return (
      <div className={classNames(className, content)}>
        {renderAst(children)}
      </div>
    );
  }
}

export default Markdown;
